<template>
  <b-container v-if="user">
    <b-row class="mt-5">
      <b-col md="6">
        <b-card class="box">
          <h2 class="text-primary">{{user.firstName}} {{user.lastName}}<a v-if="user.linkedin" :href="user.linkedin" target="_blank"> <v-icon name="brands/linkedin-in" class="icon m-3" scale="0.8" /></a></h2>
          <h5>{{user.jobTitle}}</h5>
          <h3 class="mt-4">{{user.company}}</h3>
          <h5 class="text-muted">({{user.companyType}})</h5>

          <h5 class="mt-5">Interests:</h5> 
          <p><b-badge v-for="(interest,index) in user.interests"  variant="light" :key="index">{{interest}}</b-badge></p>
          <h5 class="mt-4">Found us:</h5>
          <p>{{user.foundUs}}</p>

          <p class="text-muted small mt-4" v-if="user.timestamp">Joined Ecoystem Research on {{new Date(user.timestamp).toLocaleString()}}</p>

        </b-card>
      </b-col>
      <b-col md="6">
        <b-card class="box">
          <h4 class="mb-3 text-primary">Downloaded Reports</h4>
          <div v-for="(item,index) in user.downloadedReports" :key="index">
            <router-link target="_blank" :to="{ name: 'Report', params: { name: item.id } }">{{item.id}} - {{new Date(item.timestamp).toLocaleString()}} </router-link>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import firebase from "firebase/app";
require("firebase/firestore");
require("firebase/auth");
import util from "../support/util";
export default {
  data() {
    return {
      users: [],
    };
  },
  created() {
    if (!this.$store.state.isAdmin && !this.$store.state.isMtb) {
      this.$router.push("/reports");
    }
    this.loadUsers();
    this.scrollTop = util.scrollTop;
  },
  computed: {
    route() {
      return this.$route.query.id;
    },
    user() {
      let filteredUsers = this.users.filter((user)=>{return user.sfId})
      return filteredUsers.length!==0? filteredUsers.filter(user=>{
        if (user.downloadedReports){user.downloadedReports.sort((a, b) => (a.timestamp > b.timestamp) ? -1 : 1)}
        return user.sfId.includes(this.$route.query.id)})[0] :null
    },
  },
  methods: {
    async loadUsers() {
      try {
        const snapshot = await firebase
          .firestore()
          .collection("users")
          .get();
        let allUsers = snapshot.docs.map((doc) => doc.data());
        this.users = allUsers.filter((user) => {
          return !user.email.includes("mindthebridge") && user.hasCompletedSignup;
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped>
.box {
  border-radius: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  border-width: 0px; 
}
</style>
